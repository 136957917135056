import { createRouter, createWebHistory } from 'vue-router'
import dataRouter from './modules/data'
import sysRouter from './modules/sys'
import homeRouter from './modules/home'
import eqptRouter from "./modules/eqpt"
import eqptOpRouter from "./modules/eqptOp"
import faultAlarmRouter from './modules/faultAlarm'
import workOrder from "./modules/workOrder";
/**
 * 重置路由表
 */
export function resetRouter() {
	['dataHome','sysHome','eqptHome'].forEach(routerName => {
		if (router.hasRoute(routerName)){
			router.removeRoute(routerName)
		}

	})

}


/**
 * 私有路由表
 */
export const privateRouters = [
	sysRouter,
	dataRouter,
	eqptRouter,
	eqptOpRouter,
	faultAlarmRouter,
	workOrder,
]



/**
 * 公开路由表
 */
export const publicRouters = [
	// {
	// 	path: '/',
	// 	component: () => import('@/views/layout'),
	// 	redirect: '/home',
	// },
	{
		path: '/login',
		component: () => import(/* webpackChunkName: "login"*/'@/components/login/login-home')
	},
	{
		path: '/404',
		name: '404',
		component: () =>
			import(/* webpackChunkName: "error-page404" */ '@/views/error-page/404')
	},
	{
		path: '/401',
		name: '401',
		component: () =>
			import(/* webpackChunkName: "error-page401" */ '@/views/error-page/401')
	},

]

const router = createRouter({
  	history: createWebHistory(process.env.BASE_URL),
	routes: [homeRouter,...publicRouters]
})

export default router
