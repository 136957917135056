export default {
  path: '/',
  name: 'faultAlarmHome',
  component: () => import(/* webpackChunkName: "layout" */'@/views/layout'),
  children: [
    {
      path: '/faultAlarm',
      name: 'faultAlarmManage',
      component: () => import(/* webpackChunkName: "data" */'@/views/faultAlarm'),
      meta: {
        title: 'faultAlarmManage',
        icon: 'faultAlarmManage',
        parent: '/faultAlarmManage',
        userRouter: true,
        index: 3
      },
      children: [
      /** 热表故障 */
        {
          path: '/faultAlarm/hotMeter',
          name: 'hotMeterFault',
          component: () => import(/* webpackChunkName: "hotMeterFault" */'@/views/faultAlarm/hotMeter'),
          meta: {
            keepAlive: true,
            title: 'hotMeterFault',
            icon: 'data',
            parent: '/faultAlarm',
          }
        },
        /** 温控阀故障 */
        {
          path: '/faultAlarm/temControlValve',
          name: 'temControlValveFault',
          component: () => import(/* webpackChunkName: "temControlValveFault" */'@/views/faultAlarm/temControlValve'),
          meta: {
            keepAlive: true,
            title: 'temControlValveFault',
            icon: 'data',
            parent: '/faultAlarm',
          }
        },
        /** 平衡阀故障 */
        {
          path: '/faultAlarm/balanceValve',
          name: 'balanceValveFault',
          component: () => import(/* webpackChunkName: "balanceValveFault" */'@/views/faultAlarm/balanceValve'),
          meta: {
            keepAlive: true,
            title: 'balanceValveFault',
            icon: 'data',
            parent: '/faultAlarm',
          }
        },
        /** NB 温显器故障 */
        {
          path: '/faultAlarm/nbThermometer',
          name: 'nbThermometerFault',
          component: () => import(/* webpackChunkName: "nbThermometerFault" */'@/views/faultAlarm/nbThermometer'),
          meta: {
            keepAlive: true,
            title: 'nbThermometerFault',
            icon: 'data',
            parent: '/faultAlarm',
          }
        },
        /** 流量计 */
        {
          path: '/faultAlarm/flowmeter',
          name: 'flowmeterFault',
          component: () => import(/* webpackChunkName: "flowmeterFault" */'@/views/faultAlarm/flowmeter'),
          meta: {
            keepAlive: true,
            title: 'flowmeterFault',
            icon: 'data',
            parent: '/faultAlarm',
          }
        },
        /** 压力显示器 */
        {
          path: '/faultAlarm/pressureDisplay',
          name: 'pressureDisplayFault',
          component: () => import(/* webpackChunkName: "pressureDisplayFault" */'@/views/faultAlarm/pressureDisplay'),
          meta: {
            keepAlive: true,
            title: 'pressureDisplayFault',
            icon: 'data',
            parent: '/faultAlarm',
          }
        },

      ]
    }
  ]
}