/**
 * 获取字典类型
 */
import store from '@/store'
import { findAllLibrary, findLibrary } from "@/api/sys/dict";
import { isNull } from "@/utils/fangerUtils";
import {getAllProtocolList} from "@/api/sys/protocol"
import { findUserByOrganIds } from "@/api/sys/user";
/**
 * 获取指定字典
 * @param {*} dictKey 
 * @returns 
 */
export const getDictByName = dictKey => {
  return store.getters.dictOption[dictKey]
}


/**
 * 获取字典名称
 * @param dictKey 字典key
 * @param dictVal 字典code
 * @returns {*}
 */
export const getDictName = function (dictKey, dictVal) {
  if (dictVal === undefined) return ''
  if (store.getters.dictOption[dictKey]) {
    let reVal = dictVal
    store.getters.dictOption[dictKey].forEach(item => {
      if (item.libraryCode === dictVal) {
        reVal = item.libraryName
        return
      }
    })
    return reVal
  } else {
    return dictVal
  }
}

/**
 * 获取字典名称列表
 * @param dictKey
 * @param dictVal
 */
export const getDictNameList = (dictKey, dictVal) => {
  if (dictVal === undefined || dictVal === null) return []
  if ((store.getters.dictOption[dictKey])) {
    const reVal = []
    const dictValList = dictVal.split(',')
    store.getters.dictOption[dictKey].forEach(item => {
      if (dictValList.indexOf(item.libraryCode) > -1) {
        reVal.push(item.libraryName)
      }
    })
    return reVal
  } else {
    return [dictVal]
  }
}
const getAllUser = async () => {
  const allUser = [];
  await findUserByOrganIds().then((res) => {
    res.data.forEach((item) => {
      if (item.admin !== '1') {
        allUser.push({ libraryCode: item.userId, libraryName: item.userName });
      }
    });
  });

  return allUser;
};

/**
 * 获取字典名称，返回字典
 * @param {*} dictKey 
 * @param {*} dictVal 
 * @returns 
 */
export const getDictNameDict = (dictKey, dictVal, dictOption = {}) => {
  if (dictVal === undefined || dictVal === null) return {}
  if (isNull(dictOption)) {
    dictOption = store.getters.dictOption
  }
  const dictValList = dictVal.split(',')
  if (dictOption[dictKey]) {
    const reVal = {}
    dictOption[dictKey].forEach(item => {
      if (dictValList.indexOf(item.libraryCode) > -1) {
        reVal[item.libraryCode] = item.libraryName
      }
    })
    return reVal
  } else {
    const reVal = {}
    dictValList.forEach(item => {
      reVal[item] = item
    })
    return reVal
  }
}

// 启动加载字典
const startLoadingDictKeys = ['businessCode']
let allDictKeys = []


/**
 * 初始化所有字典
 * 调用一次字典类型，返回所有数据，循环层级
 */
export const installAllDict = async function () {
  // 获取所有字典类型
  store.commit('cache/setDictOption', { dictKey: 'nodeType', dictVal: getNodeTypeList() })
  // 星期
  store.commit('cache/setDictOption', { dictKey: 'week', dictVal: getWeekList() })

  // 获取协议权限
  store.commit('cache/setDictOption', { dictKey: 'protocolPermission', dictVal: await getProtocolPermissionList() })

  // 获取当前登录账号所能管理的账号
 setUserCache();
  const allParentDict = []  // 所有的父级字典列表
  const allSubDict = {} // 所有的子集字典
  await findAllLibrary().then(resData => {
    resData.data.forEach(item => {
      if (item.libraryParentId === '0') {
        allParentDict.push(item)
      } else {
        const subList = allSubDict[item.libraryParentId]
        if (subList) {
          subList.push(item)
        } else {
          allSubDict[item.libraryParentId] = [item]
        }
      }
    })

    for (let dictTypeItem of allParentDict) {
      let subList = allSubDict[dictTypeItem.libraryId]
      if (!subList) {
        subList = []
      }
      subList = iterationChildDict(allSubDict, subList)
      store.commit('cache/setDictOption', { dictKey: dictTypeItem.libraryCode, dictVal: subList })
    }
  })
}

// 迭代自己字典
const iterationChildDict = (allSubDict, subDictList) => {
  if (subDictList.length > 0) {
    subDictList.forEach(item => {
      let subList = allSubDict[item.libraryId]
      if (!subList) {
        subList = []
      }
      item.child = iterationChildDict(allSubDict, subList)
    })
  }
  return subDictList

}



/**
 * 初始化所有字典
 * 根据每个字典类型，调用次结构，每次只返回每一个字典类型的数据
 */
export const installAllDict1 = function () {
  // 获取所有字典类型
  store.commit('cache/setDictOption', { dictKey: 'nodeType', dictVal: getNodeTypeList() })
  findLibrary({
    pageIndex: 1,
    pageSize: 1000
  }).then(resData => {
    allDictKeys = resData.data.map(item => item.libraryCode)
    // loadDict(startLoadingDictKeys)
    loadDict(allDictKeys)
  })
}

export const loadDict = dictKeys => {
  dictKeys.forEach(async key => {
    // 判断字典列表是否包含key
    if (allDictKeys.includes(key)) {
      await store.dispatch('cache/getDictOption', key)
    }
  })
}


/**
 * 节点类型
 */
export const getNodeTypeList = () => {
  return [
    { libraryCode: 'root', libraryName: '根节点' },
    { libraryCode: 'company', libraryName: '公司' },
    { libraryCode: 'company_unit', libraryName: '部门' },
    { libraryCode: 'area', libraryName: '小区' },
    { libraryCode: 'building', libraryName: '楼' },
    { libraryCode: 'unit', libraryName: '单元' },
    { libraryCode: 'family', libraryName: '户' },
  ]
}

// 获取节点类型的下标
export const getNodeTypeByType = typeVal => {
  let typeIndex = 0
  const nodeTypeList = getNodeTypeList()
  try {
    nodeTypeList.forEach(item => {
      if (item.libraryCode === typeVal) {
        throw new Error('结束循环')
      } else {
        typeIndex = typeIndex + 1
      }
    })
  } catch (e) {

  }
  return { index: typeIndex, type: nodeTypeList[typeIndex] }
}

/**
 * 获取节点类型的下标
 */
export const getNodeTypeIndex = typeVal => {
  return getNodeTypeByType(typeVal).index
}

/**
 * 获取节点类型的名字
 */
export const getNodeTypeName = typeVal => {
  if (isNull(typeVal)) {
    return ''
  }
  return getNodeTypeByType(typeVal).type.libraryName
}


/**
 * 获取星期
 * @returns
 */
const getWeekList = () => {
  return [
    { libraryCode: '1', libraryName: '星期日' },
    { libraryCode: '2', libraryName: '星期一' },
    { libraryCode: '3', libraryName: '星期二' },
    { libraryCode: '4', libraryName: '星期三' },
    { libraryCode: '5', libraryName: '星期四' },
    { libraryCode: '6', libraryName: '星期五' },
    { libraryCode: '7', libraryName: '星期六' },
  ]
}

/**
 * 获取协议权限
 */
const getProtocolPermissionList = async () => {
  const resDict = {}
  const resData = await getAllProtocolList()
  if (resData.status === 1) {
    resData.data.forEach(item => {
      if (item.delflag !== "D") {
        resDict[item.protocolCode] = item.protocolSupportOperation.split(',')
      }
    })
  }
  return resDict
}

/**
 * 获取用户的公司id, 多个公司用逗号拼接
 * @returns {Promise<*>}
 */
export const getUserCompanyStr = async () => {
  return await store.dispatch('data/getUserTreeData', false).then(resData => {
    return resData.map(item => item.nodeId).join(',')
  })
}

/**
 * 设置用户账号缓存
 */
const setUserCache = async() => {
  // 获取当前登录账号所能管理的账号
  store.commit("cache/setDictOption", {
    dictKey: "allUserAccount",
    dictVal: await getAllUser(),
  });
}


