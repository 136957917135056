import {getChild, getSimpleTreeNode, getSimpleTreeNodeByPermission} from '@/api/data/data'
import {findRelByUserId} from "@/api/rel";
import {joinComma} from "@/utils/stringutil";
import {isAdmin} from "@/utils/admin";

let nodeId = 0
export default {
  namespaced: true,
  state: () => ({
    currentNodeData: {}, // 数据管理当前的树节点
    treeMask: false,
    userTreeData: [],
  }),
  mutations: {
    setCurrentNodeData(state, data) {
      state.currentNodeData = data
    },
    setTreeMask(state,data) {
      state.treeMask = data
    },
    setUserTreeData(state,data) {
      state.userTreeData = data
    }
  },
  actions: {
    /**
     * 获取树信息
     * */
    getTree(context) {
      return new Promise((resolve, reject) => {
        getSimpleTreeNode({}).then(data => {
          // const reData = getListData(data.data)
          isHasChild(data.data)
          if (data.data.length > 0) {
            context.commit('setUserTreeData', data.data[0].child)
            resolve(data.data[0].child)
          } else {
            context.commit('setUserTreeData', [])
            resolve([])
          }
          
          
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * 获取用户树
     * @param context
     */
    getUserTree(context, userId) {
      return new Promise((resolve, reject) => {
        const relIds = context.rootGetters.loginInfo.userDataPermissions.map(item => item.relId)
        const relIdsStr = joinComma('', relIds)
        if (relIdsStr === ''){
          resolve([])
          context.commit('setUserTreeData',[])
        } else {
          getSimpleTreeNodeByPermission(relIdsStr).then(resData => {
            isHasChild(resData.data)
            if (resData.data.length > 0) {
              context.commit('setUserTreeData',resData.data[0].child)
              resolve(resData.data[0].child)
            } else {
              context.commit('setUserTreeData',[])
              resolve([])
            }
          })
        }
      })
    },
    getUserTreeData(context,forceRefresh) {
      if (context.state.userTreeData.length === 0 || forceRefresh === undefined || forceRefresh){
        if (isAdmin()) {
          return context.dispatch('getTree')
        } else {
          return context.dispatch('getUserTree')
        }
      } else {
        return new Promise((resolve, reject) => {
          resolve(context.state.userTreeData)
        })
      }

    },
    /**
     * 通过id 获取树的子节点
     */
    getChildById(context, id) {
      // console.log('传入id',id)
      return new Promise((resolve, reject) => {
        getChild({
          nodeId: id
        }).then(data => {
          // console.log('返回数据',data)
          isHasChild(data.data)
          resolve(data.data)
        }).catch(err => {
          reject(err)
        })
      })
    }

  }
}

// 判断子节点是否有数据，如果有子节点给子节点的child 添加一个默认数据
const isHasChild = (datas) => {
  if (datas === null || datas.length === 0) return
  datas.forEach(item => {
    // 有子节点给子节点增加默认数据
    if (item.hasChild === '1' && (item.child === null || item.child.length === 0)) {
      item.child = [{
        nodeId: 'jzid0000' + nodeId,
        nodeName: '正在加载',
        child: []
      }]
      nodeId = nodeId + 1
    } else {
      isHasChild(item.child)
    }
  })
}

// 将 pid 为0 的数据找出来并传递给 data2treeDG 函数
const getListData = (data) => {
  // data 为后台取到的所有数据
  const dataArray = [];
  data.forEach(dataItem => {
    let pid = dataItem.pid;
    // 判断 pid 是否为0
    if (pid === '0') {
      dataArray.push(dataItem)
    }
  })
  data2TreeDG(data, dataArray)
  return dataArray
}


const data2TreeDG = (datas, dataArray) => {
  // 第一次循环 pid 为 0 的数据，后面循环 pid 是否和前一次循环 pid 为 0 的 id 相同
  for (let j = 0; j < dataArray.length; j++) {
    // 保存传递的对象数据
    let dataArrayIndex = dataArray[j];
    let childrenArray = [];
    // 保存该对象的 id
    let id = dataArrayIndex.id
    // 循环请求到的所有数据
    for (let i = 0; i < datas.length; i++) {
      let data = datas[i];
      // 保存当前对象的pid
      let pid = data.pid
      if (pid === id) {
        childrenArray.push(data);
      }
    }
    // childrenArray 里面是否有数据，// 有儿子节点则递归
    if (childrenArray.length > 0) {
      dataArrayIndex.children = childrenArray;
      data2TreeDG(datas, childrenArray)
    } else {
      childHasChild(dataArrayIndex)
    }
  }
}
const data2ChildTree = (dataAry) => {
  dataAry.forEach(item => {
    childHasChild(item)
  })
}

// 判断子节点是否有子节点
const childHasChild = (childITem) => {
  let hasChild = childITem.hasChild
  if (hasChild === 'true') {
    let childrenArray = [];
    childITem.children = childrenArray;
    childrenArray.push({})
  }
}