
import store from '@/store'

/**
 * 判断登录的账号是否是定义的高级账户
 * @returns {boolean}
 */
export function isAdmin() {
  return store.getters.userEntity.admin === '1'
}
export function isAdminTenant(){
  // console.log(store.getters.userEntity)
  return  store.getters.userEntity.tenantId
}