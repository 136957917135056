/**
 * 注册全局组件和指令
 */

import {
  Table,
  Ellipsis,
  Tooltip,
  Layout,
  Header,
  Sider,
  Content,
  Footer,
  Card,
  Button,
  Grid,
  GridItem,
  Row,
  Col,
  Tabs,
  TabPane,
  Poptip,
  List,
  ListItem,
  ListItemMeta,
  InputNumber,
  ColorPicker,
  Input,
  Image,
  Upload,
  Progress,
  Icon,
  Modal,
  Form,
  FormItem,
  DatePicker,
  Tag,
  Space,
  Divider,
  Switch,
  Tree,
  Checkbox,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Select,
  Option,
  OptionGroup,
  Badge,
  Notification,
  NotificationTab,
  NotificationItem,
  Scroll,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Page,
  DescriptionList,
  Description,
  Affix,
  Drawer,
  Spin,
  Steps,
  Step
} from "view-ui-plus"

import resize  from "view-ui-plus/src/directives/resize"

const components = {
  Table,
  Ellipsis,
  Tooltip,
  Layout,
  Header,
  Sider,
  Content,
  Footer,
  Card,
  Button,
  Grid,
  GridItem,
  Row,
  Col,
  Tabs,
  TabPane,
  Poptip,
  List,
  ListItem,
  ListItemMeta,
  InputNumber,
  ColorPicker,
  Input,
  Image,
  Upload,
  Progress,
  Icon,
  Modal,
  Form,
  FormItem,
  DatePicker,
  Tag,
  Space,
  Divider,
  Switch,
  Tree,
  Checkbox,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Select,
  Option,
  OptionGroup,
  Badge,
  Notification,
  NotificationTab,
  NotificationItem,
  Scroll,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Page,
  DescriptionList,
  Description,
  Affix,
  Drawer,
  Spin,
  Steps,
  Step
}

export default (app) => {
  for (const comKey in components) {
    app.component(comKey, components[comKey])
  }
  // 注册指令
  app.directive('resize',resize)
}