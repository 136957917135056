/**
 * 权限
 */

import {privateRouters, publicRouters} from "@/router";
import {isAdmin,isAdminTenant} from "@/utils/admin";

export default {
  namespaced: true,
  state: () => ({
    // 路由表，初始时所拥有的路由表
    permissionRoutes: publicRouters,
    operationList: [],
    permissionTrees:[]
  }),
  mutations: {
    /**
     * 增加路由
     * @param state
     * @param newRoutes
     */
    setPermissionRoutes(state, newRouters) {
      state.permissionRoutes = [...publicRouters, ...newRouters]
    },
    setPermissionTrees(state, newRouters) {
      state.permissionTrees = newRouters
    },
    setOperationList(state, operationObjectList) {
      const operationList = []
      operationObjectList.forEach(item => {
        if(item.operationType === 'F'){
          operationList.push(item.operationCode)
        }
      })
      state.operationList = operationList
    }
  },
  actions: {
    /**
     * 根据权限数据筛选路由
     * @param context
     * @param menus 权限数据
     */
    filterRoutes(context, menus) {
      // 筛选之后，获取到需要通过 addRoute 进行添加的路由表数组
      const routers = []

      const menuCodes = menus.map(item => item.operationCode)
      let adminFlag = isAdmin() // 判断是否为高级账户
      let AdminTenantFlag = isAdminTenant() // 判断是否租户管理员
      routers.push(...getUserRouters(privateRouters, menuCodes, adminFlag,AdminTenantFlag))

      // menus.forEach( key => {
      //   routers.push(...privateRouters.filter(item => item.name === key.operationCode))
      // })
      // // 所有不匹配的路由，全部进入 404 的路由配置
      // 该配置必须在左右路由之后
      routers.push({
        path: '/:pathMatch(.*)',
        name: null,
        redirect: '/404'
      })
      context.commit('setPermissionRoutes', routers)
      // 设置操作
      context.commit('setOperationList', menus)
      return routers
    },
    getUserPermissionTrees(context,trees){
      context.commit('setPermissionTrees', trees)
    }
  }
}

// 获取用户路由
const getUserRouters = (priRoutes, userRoutes, adminFlag,isAdminTenant) => {  
  const resRouters = []
  priRoutes.forEach(routerItem => {
    // 判断用户是否包含该路由
    // admin 权限用户直接放过 || 跟路由放过 || 用户包含该路由
    if (adminFlag || userRoutes.indexOf(routerItem.name) !== -1 || routerItem?.meta?.userRouter) {
      //非租客管理员用户
      if(isAdminTenant && routerItem.name == 'tenantManage' ){
        return
      }  
      // 判断该路由是否有子集，有子集进行迭代
      if (routerItem.children && routerItem.children.length > 0) {
        routerItem.children = getUserRouters(routerItem.children, userRoutes, adminFlag,isAdminTenant)
      }
      // 所有菜单默认打开子路由的第一个路由
      if (routerItem.path !== '/' && routerItem.children && routerItem.children.length > 0) {
        if (!routerItem.children[0].meta.notRedirect) {
          routerItem.redirect = routerItem.children[0].path
        }
      }
      resRouters.push(routerItem)
    }
  })
  return resRouters
}