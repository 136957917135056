/**
 * redis api
 */

import { axiosPost } from "@/utils/request"


/**
 * 获取redis 通过key
 * @param {*} key 
 * @returns 
 */
export const getRedisByKey = key => {
  return axiosPost({
    url: "/redis/getString",
    data: {
      key: key
    },
    loading: false
  })
}

/**
 * 在redis 中存储数据
 * @param {*} key 
 * @param {*} value 
 * @param {*} seconds 
 * @returns 
 */
export const setRedis = (key, value, seconds = 0) => {
  const data = {
    'key': key,
    'value': value
  }
  if (seconds > 0) {
    data['seconds'] = seconds
  }
  return axiosPost({
    url: "/redis/setString",
    data: data,
    loading: false,
  })
} 