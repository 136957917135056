import { exportAllData, exportData, setData } from "@/api/data/data";
import { joinComma, joinSymbol } from "@/utils/stringutil";
import { getDictName, getDictNameList,getDictNameDict } from "@/utils/cache";
import { formateDate,formateDataByArray } from "@/filter";
import { warnMsg } from "@/utils/msgBox.ts";
import { param } from "jquery";

/**
 * 判断数据是否为空值
 */
export function isNull(data) {
  if (data instanceof Array) {
    if (JSON.stringify(data) === '[]') return true
  } else if (data instanceof Object) {
    if (JSON.stringify(data) === '{}') return true
  } else {
    if (!data) return true
  }
  return false
}


/**
 * 对象根据另一个对象进行赋值,只赋值自身有的属性
 */
export const assignmentSelfProp = (object, assObject) => {
  for (let key in object) {
    if (assObject.hasOwnProperty(key)) {
      object[key] = assObject[key]
    }
  }
}

/**
 * 对象根据另一个对象进行赋值,赋值全部属性
 * @param {*} object 
 * @param {*} assObject 
 */
export const assignmentAllProp = (object, assObject) => {
  for (let key in assObject) {
    object[key] = assObject[key]
  }
}




// 设置数据的父节点等信息
export const setParentId = (parentNode, data) => {
  data.parentId = parentNode.nodeId // 当前节点父ID
  // 对 companyId 和 companyUnitId 属性进行赋值
  if (parentNode.nodeType !== 'root') {
    if (parentNode.companyId === '' || parentNode.companyId === null) {
      data.companyId = parentNode.nodeId
    } else {
      data.companyId = parentNode.companyId
      if (parentNode.companyUnitId === '' || parentNode.companyUnitId === null) {
        data.companyUnitId = parentNode.nodeId
      } else {
        data.companyUnitId = parentNode.companyUnitId
      }
    }
  }
  // 对是否有下级节点进行赋值
  if (data.nodeType !== 'family') {
    data.hasChild = '1'
  } else {
    data.hasChild = '0'
  }
}


// 获取时间
export const getDay = (day) => {
  const data = new Date()
  let targetDay_milliseconds = data.getTime() + 1000 * 60 * 60 * 24 * day
  data.setTime(targetDay_milliseconds)

  return getFormatDate(data)

}
// 获取格式化的日期
export const getFormatDate = (date) => {
  let y = date.getFullYear()
  let m = date.getMonth()
  let d = date.getDate()

  m = doHandleMonth(m + 1)
  d = doHandleMonth(d)
  const resDate = `${y}-${m}-${d}`

  return resDate
}

// 获取读取时间日期
export const getReadingTimeDate = dateList => {
  return dateList[0] + '-' + doHandleMonth(Number(dateList[1])) + '-' + doHandleMonth(Number(dateList[2]))
}
// 如果为个位数在前边加0
const doHandleMonth = (month) => {
  let m = month
  if (month.toString().length === 1) {
    m = '0' + month
  }
  return m
}



// 深度拷贝
export const deepClone = obj => {
  if (obj === null) return obj
  //判断拷贝的obj是对象还是数组
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") { //obj不能为空，并且是对象或者是数组 因为null也是object
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] && typeof obj[key] === "object") { //obj里面属性值不为空并且还是对象，进行深度拷贝
          objClone[key] = deepClone(obj[key]); //递归进行深度的拷贝
        } else {
          objClone[key] = obj[key]; //直接拷贝
        }
      }
    }
  }
  return objClone;
}



// 导出数据
export const doExportData = (radio, excelHeader, selectVals, curPageData, allDataParams, allDataUrl) => {
  if (radio === 1) {
    if (selectVals === null || selectVals.length === 0) {
      warnMsg('未选择数据，无法进行导出！')
      return
    }
    // 选中数据
    exportData(getExportData(selectVals, excelHeader))
  } else if (radio === 2) {
    if (curPageData === null || curPageData.length === 0) {
      warnMsg('当前页无数据，无法进行导出！')
      return
    }
    // 当前页数据
    exportData(getExportData(curPageData, excelHeader))
  } else if (radio === 3) {
    // 全部数据
    const headersKey = excelHeader.map(item => item.prop)
    const headersValue = excelHeader.map(item => item.label)

    // 字典数据
    const libraryInfoList = []
    excelHeader.forEach(item => {
      if (item.dictKey) {
        libraryInfoList.push(`${item.prop}-${item.dictKey}`)
      }
    })
    
    exportAllData({
      json: JSON.stringify({
        params: allDataParams,
        url: allDataUrl,
        headersKey: headersKey.join(','),
        headersValue: headersValue.join(','),
        libraryInfo: libraryInfoList.join(','),
      })
    })
  }
}


// 获取导出数据
const getExportData = (exprotData, excelHeader) => {
  const labels = excelHeader.map(item => item.label)
  const exportLabel = joinComma('', labels)
  const exportVal = []
  exprotData.forEach(item => {
    let valStrs = []
    excelHeader.forEach(itemCol => {
      valStrs.push(formatData(item, itemCol))
    })
    exportVal.push(valStrs.join(','))
  })
  return { json: JSON.stringify({ headersValue: exportLabel, record: exportVal }) }
}


// 格式化数据
const formatData = (itemVal, itemCol) => {
  let resStr = ''
  if (itemCol.itemType === 'time' || itemCol.itemType === 'timeBetween') {
    resStr = formateDate(itemVal[itemCol.prop])
  } else if (itemCol.itemType === 'dict') {
    resStr = getDictName(itemCol.dictKey, itemVal[itemCol.prop])
  } else if (itemCol.itemType === 'dictList') {
    resStr = joinSymbol('', getDictNameList(itemCol.dictKey, itemVal[itemCol.prop]), ';')
  } else {
    if (itemVal[itemCol.prop] !== null) {
      resStr = itemVal[itemCol.prop]
    }
  }
  return resStr
}





// 用户数循环获取选中的父节点信息
export const getParentNodeDict = nodeList => {
  const parentNodeDict = {} // 父节点列表
  const nodeDict = {}

  nodeList.forEach(item => {
    nodeDict[item.nodeId] = item
  })


  nodeList.forEach(item => {
    const ParentNode = getParentNode(nodeDict, item)
    if(item.nodeId.indexOf('xnzjd') != -1) return 
    if (!Object.hasOwn(parentNodeDict, ParentNode.nodeId)) {
      parentNodeDict[item.nodeId] = ParentNode
    }
  })
  return parentNodeDict

}

// 迭代查找父节点
const getParentNode = (nodeDict, node) => {
  if (Object.hasOwn(nodeDict, node.parentId)) {
    return getParentNode(nodeDict, nodeDict[node.parentId])
  } else {
    return node
  }
}


/**
 * 批量更新设备属性
 * @param {*} eqptList 设备属性列表
 * @param {*} attrDict 需要更新的属性字段
 * @returns 
 */

export const updateEqptAttrBatch = (eqptList, attrDict) => {
  const reEqptList = []
  eqptList.forEach(item => {
    for (let [key, value] of Object.entries(attrDict)) {
      const _item = { ...item }
      _item[key] = value
      _item.operateTime = formatData(_item.operateTime)
      _item.readingTime = formatData(_item.readingTime)
      reEqptList.push(_item)
    }
  })
  return reEqptList

}


/**
 * 根据用户树，获取当前用户的campanyId
 */
const nodeDict = {}
export const getCurNodeCampanyId = (userTree, curNodeId) => {
  if (!isNull(nodeDict[curNodeId])) return nodeDict[curNodeId]
  userTree.forEach(item => {
    if (isNull(nodeDict[item.nodeId])) {
      if (item.nodeType === "company") {
        nodeDict[item.nodeId] = item.nodeId
      } else {
        nodeDict[item.nodeId] = nodeDict[item.parentId]
      }
    }
    if (!isNull(item.child) && item.child.length > 0) {
      getCurNodeCampanyId(item.child, curNodeId)
    }
  })
  if (!isNull(nodeDict[curNodeId])) return nodeDict[curNodeId]
}


/**
 * 数据拼接成字符串
 * @param {Array} list 数组
 * @param {String} attr 数组中对象的字段
 * @param {String} joinStr 拼接的字符串
 * @returns 
 */
export const feArrayObjectJoin = (list, attr, joinStr) => {
  if (!list) return null
  if (list.length > 0) {
    return list.map(item => item[attr]).join(joinStr)
  } else {
    return null
  }
}
/**
 * 数据拼接成字符串
 根据表头类型返回数据
 */
export const getTextVal = (param,val) =>{
  let obj={
    val:'',
    type:'str'
  }
  if(!param.itemType){
     obj.val = val
  }
  // 时间类型
  if (param.itemType === "time" || param.itemType === 'timeBetween') {
    obj.val = formateDate(val, param.timeFormat)
  }
  // 将数组转为日期
  else if (param.itemType === "arrayTime") {
    obj.val = formateDataByArray(val, param.arrayLen)
  }
  // 单独字典类型
  else if (param.itemType === "dict") {
    obj.val =  getDictName(param.dictKey, val)
  }
  // 多个字典类型
  else if (param.itemType === "dictList") {
    const dictNames = getDictNameDict(param.dictKey, val)
    if(Object.keys(dictNames).length>0){
      const arr = []
      for (const key of Object.keys(dictNames)) {
        let tagType = param.dictTagDefaultType
        if (param.dictSuccessTagList && param.dictSuccessTagList.indexOf(key) > -1) {
          tagType = "success"
        } else if (param.dictInfoTagList && param.dictInfoTagList.indexOf(key) > -1) {
          tagType = "info"
        } else if (param.dictWarningTagList && param.dictWarningTagList.indexOf(key) > -1) {
          tagType = "warning"
        } else if (param.dictDangerTagList && param.dictDangerTagList.indexOf(key) > -1) {
          tagType = "danger"
        } else if (param.dictDefaultTagList && param.dictDefaultTagList.indexOf(key) > -1) {
          tagType = ""
        }
        arr.push(dictNames[key]) 
      }
      obj.val = arr.join('、')
      obj.type = 'dictList'
    }else{
      obj.val = ''
    }
  }
  // 显示结果进行数值换算
  else if (param.itemType === "digitalScale") {
    let _val = val
    if (param.scaleExceptUnit && _val) {
      // 除换算
      _val = Number(_val) / param.scaleExceptUnit
      _val = _val.toFixed(2)
    } else if (param.scaleRideUnit && _val) {
      // 乘换算
      _val = Number(_val) * param.scaleRideUnit
      _val = _val.toFixed(2)
    }
    obj.val = _val
  }else{
    obj.val = val
  }
  return obj
}