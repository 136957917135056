/**
 * 关联api
 */
import {axiosPost} from "@/utils/request";

/**
 * 添加关联
 * @param data
 * @returns {Promise<unknown>}
 */
export const  relAdd = data => {
  return axiosPost({
    url: '/userDataPermission/insertRel',
    data: data,
    tips: true,
    loading: false
  })
}


/**
 * 删除关联
 * @param userId
 * @returns {Promise<unknown>}
 */
export const relDelete = userId => {
  return axiosPost({
    url: '/userDataPermission/daleteRel',
    data: {
      userId: userId
    },
    loading: false
  })
}
/**
 * 查询关联
 * @param userId
 * @returns {Promise<unknown>}
 */
export const findRelByUserId = userId => {
  return axiosPost({
    url: '/userDataPermission/selectUserDataPermissionByUserId',
    data: {
      userId: userId
    },
    loading: false
  })
}
/**
 * 设置用户权限
 * @param {*} data 
 * @returns 
 */
export const setUserDataPerimission = (data) => {
  return axiosPost({
    url: "/userDataPermission/setUserDataPermission",
    data: data,
    loading: false
  })
}
/**
 * 设置管理权限
 * @param {*} data 
 * @returns 
 */
export const setUserAdminPermission = (data) => {
  return axiosPost({
    url: "/adminNodeEqptRel/setUserNodeAdminPermission",
    data: data,
    loading: false
  })
}
/**
 * 查询管理权限
 * @param {*} data 
 * @returns 
 */
export const selectUserAdminPermissionByUserId = data => {
  return axiosPost({
    url: '/adminNodeEqptRel/selectUserNodeAdminPermissionByUserId',
    data: data,
    loading: false
  })
}
