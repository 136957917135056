export default {
  path: '/',
  name: 'eqptOpHome',
  component: () => import(/* webpackChunkName: "layout" */'@/views/layout'),
  children: [
    {
      path: '/eqptOp',
      name: 'eqptOpManage',
      component: () => import(/* webpackChunkName: "eqptOpManage" */'@/views/eqptOp'),
      meta: {
        title: 'eqptOpManage',
        icon: 'eqptOpManage',
        parent: '/eqptOpManage',
        userRouter: true,
        index: 2
      },
      children: [
        /**热表控制 */
        {
          path: '/eqptOp/hotMeter',
          name: 'hotMeterControl',
          component: () => import(/* webpackChunkName: "hotMeterControl" */'@/views/eqptOp/hotMeter'),
          meta: {
            keepAlive: true,
            title: 'hotMeterControl',
            icon: 'data',
            parent: '/eqptOp',
          }
        },
        /**温控阀控制 */
        {
          path: '/eqptOp/temControlValve',
          name: 'temControlValveControl',
          component: () => import(/* webpackChunkName: "temControlValveControl" */'@/views/eqptOp/temControlValve'),
          meta: {
            keepAlive: true,
            title: 'temControlValveControl',
            icon: 'data',
            parent: '/eqptOp',
          }
        },
        /**平衡阀控制 */
        {
          path: '/eqptOp/balanceValve',
          name: 'balanceValveControl',
          component: () => import(/* webpackChunkName: "balanceValveControl" */'@/views/eqptOp/balanceValve'),
          meta: {
            keepAlive: true,
            title: 'balanceValveControl',
            icon: 'data',
            parent: '/eqptOp',
          }
        },
        /**ng温显器控制 */
        {
          path: '/eqptOp/nbThermometer',
          name: 'nbThermometerControl',
          component: () => import(/* webpackChunkName: "nbThermometerControl" */'@/views/eqptOp/nbThermometer'),
          meta: {
            keepAlive: true,
            title: 'nbThermometerControl',
            icon: 'data',
            parent: '/eqptOp',
          }
        },
        /**流量计控制 */
        {
          path: '/eqptOp/flowmeter',
          name: 'flowmeterControl',
          component: () => import(/* webpackChunkName: "flowmeterControl" */'@/views/eqptOp/flowmeter'),
          meta: {
            keepAlive: true,
            title: 'flowmeterControl',
            icon: 'data',
            parent: '/eqptOp',
          }
        },
        /**压力温显器控制 */
        {
          path: '/eqptOp/pressureDisplay',
          name: 'pressureDisplayControl',
          component: () => import(/* webpackChunkName: "pressureDisplayControl" */'@/views/eqptOp/pressureDisplay'),
          meta: {
            keepAlive: true,
            title: 'pressureDisplayControl',
            icon: 'data',
            parent: '/eqptOp',
          }
        },
        /**控制履历*/
        {
          path: '/eqptOp/controlResume',
          name: 'controlResume',
          component: () => import(/* webpackChunkName: "controlResume" */'@/views/eqptOp/controlResume'),
          meta: {
            keepAlive: true,
            title: 'controlResume',
            icon: 'data',
            parent: '/eqptOp',
            subMenu: 'valveResume'
          }
        },
        /**控制履历*/
        {
          path: '/eqptOp/controlResume',
          name: 'controlResume',
          component: () => import(/* webpackChunkName: "controlResume" */'@/views/eqptOp/controlResume'),
          meta: {
            keepAlive: true,
            title: 'controlResume',
            icon: 'data',
            parent: '/eqptOp',
            subMenu: 'valveResume'
          }
        },
        /** 控制履历lindorm */
        {
          path: '/data/valveResumeLindom',
          name: 'valveResumeLindom',
          component: () => import(/* webpackChunkName: "waterMonthLindom" */'@/views/eqptOp/valveResumeLindom'),
          meta: {
            keepAlive: true,
            title: 'valveResumeLindom',
            icon: 'data',
            parent: '/eqptOp',
            subMenu: 'valveResume'
          }
        } 
      ]
    }
  ]
}