/**
 * 通讯协议接口
 */

import { axiosPost } from "@/utils/request"

/**
 * 获取协议列表
 * @param {*} data 
 * @returns 
 */
export const getProtocolList = data => {
  return axiosPost({
    url: "/eqptProtocolControllor/selectEqptProtocolInfoPage",
    data: data,
    loading: false
  })
}

/**
 * 新增协议
 * @param {*} data 
 * @returns 
 */
export const addProtocol = data => {
  return axiosPost({
    url: "/eqptProtocolControllor/insertEqptProtocolInfo",
    data: data,
    loading: false,
    tips: true
  }) 
}

/**
 * 更新协议信息
 * @param {*} data 
 * @returns 
 */
export const updateProtocol = data => {
  return axiosPost({
    url: "/eqptProtocolControllor/updateEqptProtocolInfo",
    data: data,
    loading: false,
    tips: true
  }) 
}

/**
 * 删除协议
 * @param {*} id 
 * @returns 
 */
export const deleteProtocolById = id => {
  return axiosPost({
    url: "/eqptProtocolControllor/deleteEqptProtocolInfo",
    data: {
      protocolId: id
    },
    loading: false,
    tips: true
  }) 
}

/**
 * 验证设备是否具备该按钮功能
 * @param {*} eqptIds  设备id,多个逗号拼接
 * @param {*} btnCodes 按钮code
 * @returns 
 */
export const verifyProtocol = (eqptIds, btnCodes) => {
  return axiosPost({
    url: "/eqptProtocolControllor/verifySupporteOperationInfoList",
    data: {
      eqptIds: eqptIds,
      supportOperation: btnCodes,
    },
    loading: false
  })
}

/**
 * 获取搜索设备协议
 */
export const getAllProtocolList = () => { 
  return axiosPost({
    url: "/eqptProtocolControllor/selectEqptProtocolInfoList",
    loading: false
  })
}



/**
 * 验证采集器是否具备该按钮功能
 * @param {String} collectorIds 采集器id ,多个使用逗号拼接
 * @param {String} btnCodes 按钮 code
 * @returns 
 */
export const verifyCollectorProtocol = (collectorIds, btnCodes) => {
  return axiosPost({
    url: "/eqptProtocolControllor/verifyCollectorSupporteOperationInfoList",
    data: {
      collectorIds: collectorIds,
      operation: btnCodes,
    },
    loading: false
  })
}




/**
 * 根据协议code 查询协议所有属性列表
 * @param {*} protocolCode 
 * @returns 
 */
export const getAllProtocolAttrList = protocolCode => {
  return axiosPost({
    url: "/eqptProtocolPropertyRel/selectAllPropertiesByProtocolCode",
    data: {
      protocolCode
    }
  })
}

/**
 * 根据协议code 查询协议属性列表
 * @param {*} protocolCode 
 * @returns 
 */
export const getProtocolAttrList = protocolCode => {
  return axiosPost({
    url: "/eqptProtocolPropertyRel/selectBindPropertiesByProtocolCode",
    data: {
      protocolCode
    }
  })
}


/**
 * 绑定协议属性
 * @param {*} data 
 * @returns 
 */
export const bindProtocolAttr = data => {
  return axiosPost({
    url: "/eqptProtocolPropertyRel/bindProperties4Protocol",
    data: data
  })
}


/**
 * 获取设备协议属性列表
 * @param {*} eqptId 
 * @returns 
 */
export const getEqptProtocolAttrList = eqptId => {
  return axiosPost({
    url: "/eqptProtocolPropertyRel/selectEqptPropertyInfoByEqptId",
    data: {
      eqptId
    },
    loading: false
  })
}