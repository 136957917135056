/**
 * 用户 api
 */
import {axiosPost} from "@/utils/request";
import md5 from "md5";

/**
 *  查询所有用户
 * @param data
 * @returns {Promise<unknown>}
 */
export const findUser = (data) => {
  return axiosPost({
    url: 'ap-system/findUser',
    data: data,
    loading: false

  })
}
/**
 * 根据组织结构id 查询用户
 * @param data
 * @returns {Promise<unknown>}
 */
export const findUserByOrganIds = (data) => {
  return axiosPost({
    // url: 'ap-system/findUserByOrganIdsIncludeChild',
    url: 'ap-system/findUserByPermissionIncludeChild',
    data: data,
    loading: false
  })
}
/**
 * 查询用户详情
 * @param userId
 * @returns {Promise<unknown>}
 */
export const findUserInfo = (userId) => {
  return axiosPost({
    url: 'ap-system/findUserInfo',
    data:{
      userId:userId
    },
    loading: false
  })
}

/**
 * 保存用户
 * @param data
 * @returns {Promise<unknown>}
 */
export const saveUser = (data) => {
  const inData = addOrUpdateData(data)
  inData.organId = data.organId
  // 初始化密码 123456
  inData.userPassword = md5('123456')
  return axiosPost({
    url: 'ap-system/saveUser',
    data: inData,
    tips: true,
    loading: false
  })
}
/**
 * 修改用户
 * @param data
 * @returns {Promise<unknown>}
 */
export const modifyUser = (data) => {
  const inData = addOrUpdateData(data)
  inData.organId = data.organId
  return axiosPost({
    url: 'ap-system/modifyUser',
    data: inData,
    tips: true,
    loading: false
  })
}

/**
 * 修改或新增数据传入的数据
 * @param data
 * @returns {{userAddress: *, userNation: *, idCard: *, userAccount: (string|[{trigger: string, message: string, required: boolean}]|*), userPosition: *, userName: (string|*), userId: (number|*)}}
 */
const addOrUpdateData = (data) => {
  const reData = {
    userId: data.userId,
    userAccount: data.userAccount,
    userName: data.userName,
    userAddress: data.userAddress,
    userPosition: data.userPosition,
    userNation: data.userNation,
    idCard: data.idCard,
    userGender: data.userGender,
    userTelnumber: data.userTelnumber,
    userMail: data.userMail,
    systemName: data.systemName,
    tenantId:data.tenantId
  }
  return reData
}

/**
 * 删除用户
 * @param data
 * @returns {Promise<unknown>}
 */
export const removeUser = (data) => {
  return axiosPost({
    url: 'ap-system/removeUser',
    data: {
      userId: data.userId
    },
    loading: false

  })
}
/**
 * 增加角色
 * @param data
 * @returns {Promise<unknown>}
 */
export const addRole = (data) => {
  return axiosPost({
    url: 'ap-system/saveUserRoleRel',
    data: {
      userId: data.userId,
      roleId: data.roleId
    },
    tips: true,
    loading: false
  })
}

/**
 * 获取角色
 * @param data
 * @returns {Promise<unknown>}
 */
export const getRole = (data) => {
  return new Promise((resolve, reject) => {
    axiosPost({
      url: 'ap-system/findUserInfo',
      data: {
        userId: data.userId
      }
    }).then(resData => {
      resolve(resData.data.roleEntities.map(item => item.roleId))
    })
  })
}

/**
 * 修改用户密码
 * @param {*} data 
 * @returns 
 */
export const updateUserPwd = (data) => {
  return axiosPost({
    url: 'ap-system/settingUserPassword',
    data: {
      oldPassword:md5(data.oldPassword),
      newPassword:md5(data.newPassword),
      token: data.token
    },
    loading: false,
    errTips: false,
  })

}

/**
 * 重置密码
 * @param {*} data 
 * @returns 
 */
export const resetPwd = (data) => {
  return axiosPost({
    url: "/ap-system/initUserPassword",
    data: data
  })
}

export const updateUserProfile = () => {

}
