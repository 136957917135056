export default {
  path: '/',
  name: 'dataHome',
  component: () => import(/* webpackChunkName: "layout" */'@/views/layout'),
  children: [
    {
      path: '/data',
      name: 'dataManage',
      component: () => import(/* webpackChunkName: "data" */'@/views/data'),
      // redirect: '/data/waterLatest',
      meta: {
        title: 'dataManage',
        icon: 'dataManage',
        parent: '/dataManage',
        userRouter: true,
        index: 1,
      },
      children: [

        /** 热表数据管理路由 */
        {
          path: '/data/hot/newest',
          name: 'hotNewest',
          component: () => import(/* webpackChunkName: "hotNewest" */'@/views/data/main/showTable/hotData/newestData'),
          meta: {
            keepAlive: true,
            title: 'hotNewest',
            icon: 'data',
            parent: '/data',
            subMenu: 'hotData'
          }
        },
        {
          path: '/data/hot/history',
          name: 'hotHistory',
          component: () => import(/* webpackChunkName: "hotHistory" */'@/views/data/main/showTable/hotData/historyData'),
          meta: {
            keepAlive: true,
            title: 'hotHistory',
            icon: 'data',
            parent: '/data',
            subMenu: 'hotData'
          }
        },
        {
          path: '/data/hot/newestLindom',
          name: 'hotNewestLindom',
          component: () => import(/* webpackChunkName: "hotNewestLindom" */'@/views/data/main/showTable/hotData/newestLindomData'),
          meta: {
            keepAlive: true,
            title: 'hotNewestLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'hotData'
          }
        },
        {
          path: '/data/hot/historyLindom',
          name: 'hotHistoryLindom',
          component: () => import(/* webpackChunkName: "hotHistoryLindom" */'@/views/data/main/showTable/hotData/historyLindomData'),
          meta: {
            keepAlive: true,
            title: 'hotHistoryLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'hotData'
          }
        },
        /** 温控阀数据管理路由 */
        {
          path: '/data/temValve/newest',
          name: 'temValveNewest',
          component: () => import(/* webpackChunkName: "temValveNewest" */'@/views/data/main/showTable/temControlValve/newestData'),
          meta: {
            keepAlive: true,
            title: 'temValveNewest',
            icon: 'data',
            parent: '/data',
            subMenu: 'temValveData'
          }
        },
        {
          path: '/data/temValve/history',
          name: 'temValveHistory',
          component: () => import(/* webpackChunkName: "temValveHistory" */'@/views/data/main/showTable/temControlValve/historyData'),
          meta: {
            keepAlive: true,
            title: 'temValveHistory',
            icon: 'data',
            parent: '/data',
            subMenu: 'temValveData'
          }
        },
        {
          path: '/data/temValve/newestLindom',
          name: 'temValveNewestLindom',
          component: () => import(/* webpackChunkName: "temValveNewestLindom" */'@/views/data/main/showTable/temControlValve/newestLindomData'),
          meta: {
            keepAlive: true,
            title: 'temValveNewestLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'temValveData'
          }
        },
        {
          path: '/data/temValve/historyLindom',
          name: 'temValveHistoryLindom',
          component: () => import(/* webpackChunkName: "temValveHistoryLindom" */'@/views/data/main/showTable/temControlValve/historyLindomData'),
          meta: {
            keepAlive: true,
            title: 'temValveHistoryLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'temValveData'
          }
        },
        /** 平衡阀数据管理路由 */
        {
          path: '/data/balanceValve/newest',
          name: 'balanceValveNewest',
          component: () => import(/* webpackChunkName: "balanceValveNewest" */'@/views/data/main/showTable/balanceValve/newestData'),
          meta: {
            keepAlive: true,
            title: 'balanceValveNewest',
            icon: 'data',
            parent: '/data',
            subMenu: 'balanceValveData'
          }
        },
        {
          path: '/data/balanceValve/history',
          name: 'balanceValveHistory',
          component: () => import(/* webpackChunkName: "balanceValveHistory" */'@/views/data/main/showTable/balanceValve/historyData'),
          meta: {
            keepAlive: true,
            title: 'balanceValveHistory',
            icon: 'data',
            parent: '/data',
            subMenu: 'balanceValveData'
          }
        },
        {
          path: '/data/balanceValve/newestLindom',
          name: 'balanceValveNewestLindom',
          component: () => import(/* webpackChunkName: "balanceValveNewestLindom" */'@/views/data/main/showTable/balanceValve/newestLindomData'),
          meta: {
            keepAlive: true,
            title: 'balanceValveNewestLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'balanceValveData'
          }
        },
        {
          path: '/data/balanceValve/historyLindom',
          name: 'balanceValveHistoryLindom',
          component: () => import(/* webpackChunkName: "balanceValveHistoryLindom" */'@/views/data/main/showTable/balanceValve/historyLindomData'),
          meta: {
            keepAlive: true,
            title: 'balanceValveHistoryLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'balanceValveData'
          }
        },
        /** NB温显器数据管理路由 */
        {
          path: '/data/nbThermometer/newest',
          name: 'nbThermometerNewest',
          component: () => import(/* webpackChunkName: "nbThermometerNewest" */'@/views/data/main/showTable/nbThermometer/newestData'),
          meta: {
            keepAlive: true,
            title: 'nbThermometerNewest',
            icon: 'data',
            parent: '/data',
            subMenu: 'nbThermometerData'
          }
        },
        {
          path: '/data/nbThermometer/history',
          name: 'nbThermometerHistory',
          component: () => import(/* webpackChunkName: "nbThermometerHistory" */'@/views/data/main/showTable/nbThermometer/historyData'),
          meta: {
            keepAlive: true,
            title: 'nbThermometerHistory',
            icon: 'data',
            parent: '/data',
            subMenu: 'nbThermometerData'
          }
        },
        {
          path: '/data/nbThermometer/newestLindom',
          name: 'nbThermometerNewestLindom',
          component: () => import(/* webpackChunkName: "nbThermometerNewestLindom" */'@/views/data/main/showTable/nbThermometer/newestLindomData'),
          meta: {
            keepAlive: true,
            title: 'nbThermometerNewestLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'nbThermometerData'
          }
        },
        {
          path: '/data/nbThermometer/historyLindom',
          name: 'nbThermometerHistoryLindom',
          component: () => import(/* webpackChunkName: "nbThermometerHistoryLindom" */'@/views/data/main/showTable/nbThermometer/historyLindomData'),
          meta: {
            keepAlive: true,
            title: 'nbThermometerHistoryLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'nbThermometerData'
          }
        },
        /** 流量计数据管理路由 */
        {
          path: '/data/flowmeter/newest',
          name: 'flowmeterNewest',
          component: () => import(/* webpackChunkName: "flowmeterNewest" */'@/views/data/main/showTable/flowmeter/newestData'),
          meta: {
            keepAlive: true,
            title: 'flowmeterNewest',
            icon: 'data',
            parent: '/data',
            subMenu: 'flowmeterData'
          }
        },
        {
          path: '/data/flowmeter/history',
          name: 'flowmeterHistory',
          component: () => import(/* webpackChunkName: "flowmeterHistory" */'@/views/data/main/showTable/flowmeter/historyData'),
          meta: {
            keepAlive: true,
            title: 'flowmeterHistory',
            icon: 'data',
            parent: '/data',
            subMenu: 'flowmeterData'
          }
        },
        {
          path: '/data/flowmeter/newestLindom',
          name: 'flowmeterNewestLindom',
          component: () => import(/* webpackChunkName: "flowmeterNewestLindom" */'@/views/data/main/showTable/flowmeter/newestLindomData'),
          meta: {
            keepAlive: true,
            title: 'flowmeterNewestLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'flowmeterData'
          }
        },
        {
          path: '/data/flowmeter/historyLindom',
          name: 'flowmeterHistoryLindom',
          component: () => import(/* webpackChunkName: "flowmeterHistoryLindom" */'@/views/data/main/showTable/flowmeter/historyLindomData'),
          meta: {
            keepAlive: true,
            title: 'flowmeterHistoryLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'flowmeterData'
          }
        },
        /** 压力显示器数据管理路由 */
        {
          path: '/data/pressureDisplay/newest',
          name: 'pressureDisplayNewest',
          component: () => import(/* webpackChunkName: "pressureDisplayNewest" */'@/views/data/main/showTable/pressureDisplay/newestData'),
          meta: {
            keepAlive: true,
            title: 'pressureDisplayNewest',
            icon: 'data',
            parent: '/data',
            subMenu: 'pressureDisplayData'
          }
        },
        {
          path: '/data/pressureDisplay/history',
          name: 'pressureDisplayHistory',
          component: () => import(/* webpackChunkName: "pressureDisplayHistory" */'@/views/data/main/showTable/pressureDisplay/historyData'),
          meta: {
            keepAlive: true,
            title: 'pressureDisplayHistory',
            icon: 'data',
            parent: '/data',
            subMenu: 'pressureDisplayData'
          }
        },
        {
          path: '/data/pressureDisplay/newestLindom',
          name: 'pressureDisplayNewestLindom',
          component: () => import(/* webpackChunkName: "pressureDisplayNewestLindom" */'@/views/data/main/showTable/pressureDisplay/newestLindomData'),
          meta: {
            keepAlive: true,
            title: 'pressureDisplayNewestLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'pressureDisplayData'
          }
        },
        {
          path: '/data/pressureDisplay/historyLindom',
          name: 'pressureDisplayHistoryLindom',
          component: () => import(/* webpackChunkName: "pressureDisplayHistoryLindom" */'@/views/data/main/showTable/pressureDisplay/historyLindomData'),
          meta: {
            keepAlive: true,
            title: 'pressureDisplayHistoryLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'pressureDisplayData'
          }
        },

        /** 数据分析路由 */
        {
          path: '/data/dataAnalysis/statisticalAnalysis',
          name: 'statisticalAnalysis',
          component: () => import(/* webpackChunkName: "statisticalAnalysis" */'@/views/data/main/showTable/dataAnalysis/statisticalAnalysis'),
          meta: {
            keepAlive: true,
            title: 'statisticalAnalysis',
            icon: 'data',
            parent: '/data',
            subMenu: 'dataAnalysis'
          }
        },
        // {
        //   path: '/data/dataAnalysis/listBrowse',
        //   name: 'listBrowse',
        //   component: () => import(/* webpackChunkName: "listBrowse" */'@/views/data/main/showTable/dataAnalysis/listBrowse'),
        //   meta: {
        //     keepAlive: true,
        //     title: 'listBrowse',
        //     icon: 'data',
        //     parent: '/data',
        //     subMenu: 'dataAnalysis'
        //   }
        // },
        {
          path: '/data/dataAnalysis/graphicAnalysis',
          name: 'graphicAnalysis',
          component: () => import(/* webpackChunkName: "graphicAnalysis" */'@/views/data/main/showTable/dataAnalysis/graphicAnalysis'),
          meta: {
            keepAlive: true,
            title: 'graphicAnalysis',
            icon: 'data',
            parent: '/data',
            subMenu: 'dataAnalysis'
          }
        },


        /** 用户详情 */
        {
          path: '/data/eqptInfo/:eqptId',
          name: 'eqptInfo',
          component: () => import(/* webpackChunkName: "eqptInfo" */'@/components/eqptInfo/eqptInfo'),
          meta: {
            keepAlive: true,
            title: 'eqptInfo',
            icon: 'data',
            parent: '/data',
            notRedirect: true,
            subMenu: ''
          }
        },
        /** 设备数据 */
        {
          path: '/data/showEqptData/:eqptId/:eqptType/:eqptCode',
          name: 'showEqptData',
          component: () => import(/* webpackChunkName: "showEqptData" */'@/views/data/main/showTable/eqptData'),
          meta: {
            keepAlive: true,
            title: 'showEqptData',
            icon: 'data',
            parent: '/data',
            notRedirect: true,
            subMenu: ''
          }
        },


      ]
    },

  ]

}