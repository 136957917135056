import router from '@/router'
import store from '@/store'
import { axiosPost } from '@/utils/request'
import { setRedis } from './redis'


/**
 * 登录
 * return
 *
 */
export const login = data => {
  return axiosPost({
    url: '/ap-system/UserLogin',
    data: data,
    loading: false,
    errTips: false
  })
}

/**
 * 退出登录
 */

export const logout = data => {
  return axiosPost({
    url: '/ap-system/logout',
    data: data
  })
}
