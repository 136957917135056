import axios from "axios";
import { alertBox, errorMsg, succesMsg } from "@/utils/msgBox.ts";
import store from "@/store";
import { ElLoading } from "element-plus";
import { getI18nStr } from "@/utils/i18n";
import { isCheckTimeout } from "@/utils/auth";
import { getDictName } from "@/utils/cache";
import qs from "qs";
import { getItem, removeAllItem } from "@/utils/storage";
import { ON_LINE_TIME } from "@/constant";
import router from "@/router";
import { confirmBox } from "./msgBox";
import { isNull } from "./fangerUtils";
import { setRedis } from "@/api/redis";
import {formateDate} from "@/filter/index"


const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
})


const requestDict = {}

// 判断请求是否重复
const stopRepeatRequest = (url, params) => {
  if (requestDict[url] && requestDict[url] === JSON.stringify(params)) {
    return true
  }
  requestDict[url] = JSON.stringify(params)
  return false
}

// 删除已经完成的请求
const allowRequest = (url) => {
  delete requestDict[url]
}

// 存储每个请求的取消函数和 axios 标识
let pending = []

let cancelToken = axios.CancelToken


// 取消之前的请求
const removePending = config => {
  for (let p in pending) {
    if (pending[p].u === config.url + '&' + config.method) {
      //当当前请求在数组中存在时执行函数体
      pending[p].f(); //执行取消操作
      pending.splice(p, 1);
    }
  }
}

// 请求拦截器
service.interceptors.request.use(
  config => {
    if (!isNull(config.headers.uniqueRequest) && !isNull(config.headers.uniqueRequest)) {
      // 在一个axios发送前执行一下取消操作
      removePending(config)
      config.cancelToken = new cancelToken(fun => {
        // 唯一标识用来判断是否是同一个请求
        pending.push({
          u: config.url + '&' + config.method,
          f: fun
        })
      })
    }

    if (stopRepeatRequest(config.url, config.params)) {
      throw new axios.Cancel('repeat request')
    }
    // 在这里统一注入 token
    if (store.getters.token) {
      if (isCheckTimeout()) {
        // 退出操作
        store.dispatch('user/logout')
        return Promise.reject(new Error('用户登录过期'))
      }
      // console.log('请求参数',config)
      config.headers.Authorization = `${store.getters.token}`

    }
    return config
  }, error => {
    return Promise.reject(error)
  })
let unLogin = 0
// 响应拦截器
service.interceptors.response.use(
  response => {
    allowRequest(response.config.url)
    // 判断当前请求是否成功
    if (response.data.status === 1) {
      return response.data
    } else {
      if (response.data.message === 'TOKEN_OUT_TIME_EXCEPTION') {
        if (unLogin === 0) {
          unLogin = 1
          const date = new Date()
          const onLineTime = getItem(ON_LINE_TIME)
          if (onLineTime === null || date - new Date(onLineTime) > 1000 * 60 * 11) {
            store.dispatch('user/logout')
            return
          } else {
            setRedis(store.getters.userEntity.userAccount + "router", router.currentRoute.value.path, 3000)
            alertBox('长时间未操作，登录超时，请重新登录', '确定', 'warning').finally(() => {
              store.dispatch('user/logout')
              return
            })
          }
        }
      } else if (response.data.message === 'UN_LOGIN') {
        if (unLogin === 0) {
          unLogin = 1
          alertBox('当前未登录，请进行登录', '确定', 'warning').finally(() => {
            store.dispatch('user/logout')
            return
          })
        }
      } else {
        unLogin = 0
        // 失败（请求成功，业务失败），消息提示
        return Promise.reject(response.data)
      }
    }
  },
  error => {
    if (error.code !== "ERR_CANCELED") {
      allowRequest(error.config.url)
    }
    return Promise.reject(error)
  },
)


let _requestParams = null

const _request = (url, method, params, tips, loading, loadText, errTips, uniqueRequest, responseType, dataType, contentType) => {
  _requestParams = {
    url,
    method,
    params,
    tips,
    loading,
    errTips,
    uniqueRequest,
    responseType,
    dataType,
    contentType,
    loadText
  }

  let LoadingInstance
  if (loading === undefined || loading === true) {
    let showLoadText = "拼命加载中"
    if (!isNull(loadText)) {
      showLoadText = loadText
    }
    LoadingInstance = ElLoading.service({
      text: showLoadText,
      background: 'rgba(255,255,255,0.3)'
    })
  }
  let response = ''
  if (responseType) {
    response = responseType
  }
  // 参数
  let dataParams = ''
  // 特殊处理数据
  const _params = handleData(params)
  if (method === 'get') {
    dataParams = {
      params: _params
    }
  } else {
    dataParams = {
      data: qs.stringify(_params, { skipNulls: true }) // qs 序列化数据 Content-Type:application/x-www-form-urlencoded
      // data: JSON.stringify(params)
    }
  }
  // 请求头
  const headers = {}
  // 判断请求是否唯一请求，有相同的请求，取消前一个请求
  if (!isNull(uniqueRequest) && uniqueRequest) {
    headers.uniqueRequest = uniqueRequest
  }
  if (dataType) {
    headers['data-type'] = dataType
  }
  if (contentType) {
    headers['content-type'] = contentType
  }
  return new Promise((resolve, reject) => {
    service.request({
      url: url,
      method: method,
      headers: headers,
      responseType: response,
      ...dataParams,
    }).then(async data => {
      if (tips && data.status === 1) {
        succesMsg(getDictName('businessCode', data.message))
      } else if (data && data.status === 2) {
        const confirm = await confirmBox(data.message, "确定", "warning")
        if (confirm === "confirm") {
          _requestParams.params.check = 0
          return _request(..._requestParams)
        }
      }
      resolve(data)
    }).catch(err => {
      if (err.type && err.type === 'application/octet-stream') {
        // 下载文件
        resolve(err)
      } else if (err.message && (err.message === 'repeat request' || err.message === "canceled")) {
        // 重复请求或被取消的请求，不进行任何操作
        // reject(err)
      } else {
        if (errTips === undefined || errTips) { // 是否进行错误提示
          // 获取错误信息
          let errMessage = ''
          if (err.status === -1) {
            errMessage = getDictName('businessCode', err.message)
          } else {
            errMessage = getI18nStr('msg.axios.' + err.message)
          }
          if (errMessage === null) {
            // errorMsg('错误信息为空') // 错误提示
            alertBox("发生未知错误，错误信息位null", "确定", "error")
          }
          else if (errMessage.indexOf('timeoutof') === -1) {
            // errorMsg(errMessage) // 错误提示
            alertBox(errMessage, "确定", "error")
          } else {
            errorMsg('连接超时') // 错误提示
          }
        }
        reject(err)
      }
    }).finally(() => {
      LoadingInstance && LoadingInstance.close()
    })
  })
}

// 特殊处理数据
const handleData = data => {
  const _reData = {}
  for (let key in data) {
    let value = data[key]
    // 时间类型
    if (key.indexOf('Time') > -1 && value instanceof Array) {
      value = formateDate(value)
    }
    // console.log(key, data[key]);
    _reData[key] = value
  }
  return _reData
}

export const axiosGet = (params) => {
  return _request(params.url, 'GET', params.data, params.tips, params.loading, params.errTips)
}

export const axiosPost = (params) => {
  // let contentType = "application/json"
  let contentType = undefined
  if (params.contentType) {
    contentType = params.contentType
  }
  return _request(params.url, 'POST', params.data, params.tips, params.loading, params.loadText, params.errTips, params.uniqueRequest, params.responseType, params.dataType, contentType)
}

export const axiosPostDownload = (params) => {
  _request(params.url, 'POST', params.data, params.tips, params.loading, params.loadText, params.errTips, params.uniqueRequest, 'blob', 'json', 'application/x-www-form-urlencoded;charset=UTF-8').then(resData => {
    downfiles(resData)
  })
}


/**
 * 下载文件
 * @param data 下载数据
 */
const downfiles = (data) => {
  let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" })
  let url = window.URL.createObjectURL(blob); // 创建一个临时 url 指向 blob 对象
  let a = document.createElement('a')
  a.href = url;
  a.download = new Date().getTime()
  a.click()
  // 释放这个临时的对象 url
  window.URL.revokeObjectURL(url)
}


/**
 * 下载 http 网址文件
 * @param {*} httpAddr 
 */
export const downFilesByHttp = httpAddr => {
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.href = httpAddr
  a.style.display = 'none'
  a.click()
  document.body.removeChild(a)
}



