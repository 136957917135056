export default {
  path: '/',
  name: 'eqptHome',
  component: () => import(/* webpackChunkName: "layout" */ '@/views/layout'),
  children:[
    {
      path: '/eqpt',
      name: 'basicsManage',
      component: () => import(/* webpackChunkName: "eqpt" */'@/views/eqpt'),
      meta: {
        title: 'basicsManage',
        icon: 'basicsManage',
        parent: '/eqpt',
        showAside: false,
        userRouter: true,
        index: 4
      },
      children: [
      // 用户管理
        {
          path: '/eqpt/userManage',
          name: 'userManage',
          component: () => import(/* webpackChunkName: "usermanage" */'@/views/eqpt/sub-views/mechanism-manage'),
          meta: {
            title: 'userManage',
            icon: 'mechanism',
            parent: '/eqpt',
            showSubAside: false,
            showSubAsideBtn: false,
          }
        },
        // 设备管理
        {
          path: '/eqpt/eqptManage',
          name: 'eqptManage',
          component: () => import(/* webpackChunkName: "eqptManage" */'@/views/eqpt/sub-views/eqpt-manage'),
          meta: {
            title: 'eqptManage',
            icon: 'water',
            parent: '/eqpt',
            showSubAside: true,
          }
        },
        // nb设备管理
        {
          path: '/eqpt/nbEqptManage',
          name: 'nbEqptManage',
          component: () => import(/* webpackChunkName: "eqptManage" */'@/views/eqpt/sub-views/nbEqptManage'),
          meta: {
            title: 'nbEqptManage',
            icon: 'nbEqptManage',
            parent: '/eqpt',
            showSubAside: false,
          }
        },
        // 采集器管理
        {
          path: '/eqpt/collectorManage',
          name: 'collectorManage',
          component: () => import(/* webpackChunkName: "collectorManage" */'@/views/eqpt/sub-views/collector'),
          meta: {
            title: 'collectorManage',
            icon: 'collector',
            parent: '/eqpt',
            showSubAside: false,
          }
        },
        // 设备仓库
        {
          path: '/eqpt/eqptWarehouse',
          name: 'eqptWarehouse',
          component: () => import(/* webpackChunkName: "eqptWarehouse" */'@/views/eqpt/sub-views/eqpt-warehouse'),
          meta: {
            title: 'eqptWarehouse',
            icon: 'recycleBin',
            parent: '/eqpt',
            // showSubAside: false,
          }
        },
        // 采集策略
        {
          path: '/eqpt/collectConf',
          name: 'collectConf',
          component: () => import(/*webpackChunkName: "collectConf" */'@/views/eqpt/sub-views/collectConf'),
          meta: {
            title: "collectConf",
            icon: 'collect',
            parent: '/eqpt'
          }
        },
        {
          path: '/eqpt/textMessage',
          name: 'textMessage',
          component: () => import(/*webpackChunkName: "textMessage" */'@/views/eqpt/sub-views/text-message'),
          meta: {
            title: "textMessage",
            icon: 'textMessage',
            parent: '/eqpt',
          }
        },
        {
          path: '/eqpt/sendTextMessage',
          name: 'sendTextMessage',
          component: () => import(/*webpackChunkName: "sendTextMessage" */'@/views/eqpt/sub-views/send-text-message'),
          meta: {
            title: "sendTextMessage",
            icon: 'sendTextMessage',
            parent: '/eqpt',
            showSubAside: false,
          }
        },
        {
          path: '/eqpt/messageLog',
          name: 'messageLog',
          component: () => import(/*webpackChunkName: "messageLog" */'@/views/eqpt/sub-views/message-log'),
          meta: {
            title: "messageLog",
            icon: 'messageLog',
            parent: '/eqpt',
            showSubAside: false,
          }
        },
        // 伪装服务
        {
          path: '/eqpt/eqptDisguiser',
          name: 'eqptDisguiser',
          component: () => import(/*webpackChunkName: "eqptDisguiser" */'@/views/eqpt/sub-views/eqpt-disguiser'),
          meta: {
            title: "eqptDisguiser",
            icon: 'disguiser',
            parent: '/eqpt'
          }
        },
        // 设备信息对接记录
        {
          path: '/eqpt/eqptDockRecords',
          name: 'eqptDockRecords',
          component: () => import(/*webpackChunkName: "eqptDockRecords" */'@/views/eqpt/sub-views/eqpt-dockRecords'),
          meta: {
            title: "eqptDockRecords",
            icon: 'dockRecords',
            parent: '/eqpt',
            showSubAside: false,
          }
        }

      ]
    }
  ]
}