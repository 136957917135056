<template>
  <div id="app">
    <span id="ztree_7161827528_31_ico" title="" treenode_ico="" class="button ico_close"
      style="background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFISURBVHgBpZMxS8NQFIXPS5NYE4niKHHQwaEuLk6CDk7ulu6Ku+APEHfBH+BPqHtBcBHUwcFJwdVmcJRQEyXPxHeeJVYTSaQHEkK497v33MsVmRLGkMnXQGZ4STKkNVGmADxLYEp9GPwR/iOZUvV0wbwDOUxeuxggiKpJV5sufMf4BozqdLWJltcoJD2EKTzVsu+IPJkyfgd6poFukKDbT3QgH/o9un9DEKc/kksBVNu3cP4s0bmOlKUUJ4/vuqvteasQWwpgld6Gi9Z0A1uXkYJ94HB5oiy0HJDbYUHOVPwdUwrgWvduY5w9SdWJg7ZqnZ1wkJWAUKYq+FVDmEw7+0s2dhdtHNzFBUBhjdxCb93Vkx8VITsLVjWgcxOhSscrTW2LEjymvtovT4orqyN2N2sLzE0aXwAeEz3LmvdgKHczw2MS457zJ6eGe6XFUKEhAAAAAElFTkSuQmCC) 0 0 no-repeat;"></span>
    <router-view v-slot="{ Component }">
      <transition>
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
    <!--    <router-view/>-->
  </div>
</template>

<script setup>
import * as echarts from 'echarts'
import { provide } from "vue";
provide('echart', echarts)

</script>


